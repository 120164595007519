



















import { Component, Prop, Vue } from 'nuxt-property-decorator';
import TheLink from '~/components/TheLink.vue';

@Component({
  components: { TheLink },
})
export default class ArticleList extends Vue {
    @Prop({
      required: true,
      type: Array,
    }) readonly articles!: ITopArticle[];

    @Prop({
      required: true,
      type: String,
    }) readonly title!: string;

    @Prop({
      required: true,
      type: Object,
    }) readonly abTitles!: { [propName: string]: string };

    getArticleUrl (article: ITopArticle): string {
      const { url, teaserUrl } = article;
      return teaserUrl || url;
    }

    getArticleTitle (article: ITopArticle): string {
      // Подробнее см https://youtrack.lifehacker.ru/issue/LH-564
      const { title, id } = article;
      const selectedAbTitle = this.abTitles[String(id)];
      return selectedAbTitle || title;
    }

    onClickToTop () {
      this.$emit('click-to-top', '/top', this.$route.name);
    }
};
