




import Vue, { PropType } from 'vue';
import { TTheme } from '~/components/ArrowMenu/ArrowMenu';
export default Vue.extend({
  name: 'ArrowMenuButton',

  props: {
    theme: {
      type: String as PropType<TTheme>,
      required: true,
    },

    icon: {
      type: String as PropType<string>,
      required: true,
    },
  },

  computed: {
    arrowClass (): Record<string, any> {
      const className = 'arrow-button';
      const { theme } = this;

      return {
        [`${className}--${theme}`]: theme,
      };
    },
  },
});
