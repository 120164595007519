






































































import Vue, { PropType } from 'vue';

import { TALKER_API_URL } from '~/constants/config';
import type { FetchTypeBySlugResponse } from '~/services/health/fetchTypeBySlug';
import { NOOP } from '~/utils';
import getSlugFromUrl from '~/utils/getSlugFromUrl';

const DEFAULT_AVATAR =
  'data:image/jpeg;base64,R0lGODlhMgAyAMQAAPP1+dzh7eXp8tHY58jR4s3V5O7x9vn6/Oru9NTa6Pz9/uns8/b4+vX2+v7+//b3+trg7MvT5MfQ4vj5+9fd6t7k7uPn8MbO4czT5PHz+NXc6dbc6dLZ6MbP4f///8TN4CH5BAAAAAAALAAAAAAyADIAAAX/YCeOZGmeaKqubJt+cCzPdG3feK7vfF8PFcMk4ivaCIGMZ+kJGJ8xDYBJHUCNmAd164FcexWuGPHVLcToR/kmQLvV61nETffGY206unGPKfViDn0wDIBogx8HhmIdg4qLWxeDE5BbjX1alUyIU5pLiH+aCohnnhODBY+eG32lnh5wcRCvSwt9A7RNfRK5HIO5RH2znraIrwSIH6GLgskBmgbJHxeaCdIfBpCj1x+QVtzLdNwwnYDjiYuX15AY3LyLGtcEmvHJGpoC0nmQfMkKnsHidLCQC0FAKBgQ5KKSwZeRDeUWUjngZIcEC/8k0lnQzsaAbBoXNaAwgwKlkKIqA3wIAQA7';

export default Vue.extend({
  name: 'CommentCard',

  text: {
    avatar: 'Аватар автора комментария',
    placeholderImageText: 'Изображение',
    image: 'Картинка, прикрепленная к комментарию',
    embed: 'Превью эмбеда',
  },

  props: {
    avatar: {
      type: Object as PropType<CommentItem['avatar']>,
      required: true,
    },

    author: {
      type: String as PropType<string>,
      required: true,
    },

    timestamp: {
      type: String as PropType<string>,
      required: true,
    },

    date: {
      type: String as PropType<string>,
      required: true,
    },

    likesCount: {
      type: Number as PropType<number | undefined>,
      default: 0,
    },

    dislikesCount: {
      type: Number as PropType<number>,
      required: true,
    },

    content: {
      type: String as PropType<string>,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    postSlug: {
      type: String as PropType<string>,
      default: null,
    },

    postType: {
      type: String as PropType<string>,
      default: null,
    },

    commentId: {
      type: Number as PropType<number>,
      required: true,
    },

    showMedia: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    files: {
      type: Array as PropType<Array<any>>,
      default: () => [],
    },

    videos: {
      type: Array as PropType<Array<any>>,
      default: () => [],
    },
  },

  data (): {mode: 'normal' | 'showEmbed' | 'showImage', differentTypePageComment: null | FetchTypeBySlugResponse} {
    return {
      mode: 'normal' as 'normal' | 'showEmbed' | 'showImage',
      differentTypePageComment: null,
    };
  },

  async fetch () {
    if (this.postType === 'health') {
      this.differentTypePageComment = await this.$healthApi.fetchTypeBySlug(this.postSlug);
    }
  },

  computed: {
    showImgPlaceholder (): boolean {
      // В виджете отключен показ медиа, потому выводим заглушку
      if (this.showMedia) {
        return false;
      }

      return !!this.files.length && !this.content;
    },

    computedLikesCount (): string {
      return this.likesCount ? `+${this.likesCount}` : '0';
    },
    computedDislikesCount (): string {
      return this.dislikesCount ? `-${this.dislikesCount}` : '0';
    },
    computedAvatar (): string {
      const avatarUrl = this.avatar && this.avatar.url ? this.avatar.url : null;
      return avatarUrl && avatarUrl !== 'unknown' ? avatarUrl : DEFAULT_AVATAR;
    },
    commentImage (): string {
      if (this.files.length) {
        const { path } = this.files[0];
        const newPath = path.replace('public/', 'storage/');
        return `${TALKER_API_URL}/${newPath}`;
      }

      return '';
    },
  },

  methods: {
    NOOP,
    showFullImage (): void {
      this.mode = 'showImage';
    },
    hideFullImage (): void {
      this.mode = 'normal';
    },
    showEmbed (): void {
      this.mode = 'showEmbed';
    },
    hideEmbed (): void {
      this.mode = 'normal';
    },
    getLink (isCommentLink: boolean): string {
      const { postSlug, postType, commentId } = this;
      const slug = this.differentTypePageComment && getSlugFromUrl(this.differentTypePageComment.url);

      switch (postType) {
        case 'recipe':
          return isCommentLink ? `/recipe/${postSlug}/#comment-${commentId}` : `/recipe/${postSlug}/`;
        case 'health':
          return isCommentLink ? `${slug}#comment-${commentId}` : `${slug}`;
        default:
          return isCommentLink ? `/${postSlug}/#comment-${commentId}` : `/${postSlug}/`;
      }
    },
  },
});
