













import Vue, { PropType } from 'vue';
import ArrowMenu from '~/components/ArrowMenu.vue';
import ArrowMenuItem from '~/components/ArrowMenu/ArrowMenuItem.vue';
export default Vue.extend({
  name: 'HeaderArrowMenu',
  components: {
    ArrowMenu,
    ArrowMenuItem,
  },
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    theme: {
      type: String as PropType<string>,
      default: 'light',
    },
  },

  data () {
    return {
      appearedItems: [] as number[],
    };
  },

  methods: {
    addItemToAppeared (id: number) {
      this.appearedItems.push(id);
    },
  },
});
