




















import Vue from 'vue';
import TileContainer from '@/containers/TilesContainer/TileContainer.vue';
import { TTile } from '~/containers/TilesContainer/types';

export default Vue.extend({
  name: 'TheAboutUs',

  components: {
    TileContainer,
  },

  data () {
    return {
      tiles: [
        {
          title: '100+',
          text: 'врачей-рецензентов',
          link: '/health/eksperty/',
          alt: 'фото-докотора',
          image: 'doctor-2',
          // image: 'doctor-2-pink',
        },
        {
          title: '4000+',
          text: 'статей о здоровье',
          link: '/health/list/',
          alt: 'фото планшета для записей',
          image: 'article-health',
          // image: 'article-health-pink',
        },
        {
          title: 'О разделе',
          text: 'как мы работаем',
          link: '/health/o-proekte/',
          alt: 'иконка информации',
          image: 'diagnosis',
        },
      ],
      enableSwiper: false,
    };
  },

  mounted () {
    if (window.innerWidth < 768) {
      this.enableSwiper = true;
    }
  },

  methods: {
    sendAnalytics (tile: TTile) {
      this.$sendYandexMetrika({
        level1: 'Клик_О нас',
        level4: tile.link,
      });
    },

    sendScrollAnalytics () {
      this.$sendYandexMetrika({
        level1: 'Скролл_О нас',
        level6: '1',
      });
    },
  },
});
