































import Vue, { PropType } from 'vue';
import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';
import AdminControlsPanel from '@/components/lh-ui/AdminControlsPanel/AdminControlsPanel.vue';
import TheLink from '~/components/TheLink.vue';
// @ts-ignore
import { getEditLinkHref } from '~/utils';
import UpdatingViewsCountMixin from '~/mixins/UpdatingViewsCountMixin';
import { ADS_LABELS } from '~/constants/ads-labels';

export default Vue.extend({
  name: 'ArticleCardSquare',
  components: {
    TheLink,
    AdminControlsPanel,
    AppPicture,
  },
  mixins: [UpdatingViewsCountMixin],
  props: {
    images: {
      type: Array as PropType<Array<IArticleImg>>,
      required: true,
    },
    defaultImages: {
      type: Array as PropType<Array<IArticleImg>>,
      default: () => [],
    },
    imgAlt: {
      type: String as PropType<string>,
      required: true,
    },
    link: {
      type: String as PropType<string>,
      required: true,
    },
    linkTarget: {
      type: String as PropType<string>,
      default: undefined,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    signText: {
      default: '',
      type: String as PropType<
        'empty' | 'partner' | 'ads' | 'press-release' | 'social-ads'
      >,
    },
    postId: {
      required: true,
      type: Number as PropType<number>,
    },
    isPromo: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
  },
  computed: {
    showAdminControlsPanel (): boolean {
      return (this.$store.state as IRootState).isAuthenticatedInWordpress;
    },

    partnerText (): string {
      return ADS_LABELS[this.signText] || '';
    },
  },
  methods: {
    getEditLinkHref,
  },
});
