
































import Vue from 'vue';
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue';
import { getFullUrl } from '~/utils';

export default Vue.extend({
  name: 'BlockPromise',

  components: {
    SvgIcon,
  },

  data () {
    return {
      promises: [
        {
          img: 'doctor',
          text: 'Над разделом работают профессиональные медицинские авторы и редакторы',
        },
        {
          img: 'medical-practitioner',
          text: 'Мы рецензируем статьи с врачами из ведущих клиник доказательной медицины России',
        },
        {
          img: 'medical-file',
          text: 'Мы опираемся на научные источники, которым доверяют эксперты по всему миру, и оставляем ссылки на них в материалах',
        },
        {
          img: 'heart-hand',
          text: 'Мы регулярно обновляем тексты, чтобы вы получали самые свежие рекомендации о здоровье',
        },
      ],
    };
  },

  methods: {
    sendAnalytics () {
      this.$sendYandexMetrika({
        level1: 'Клик_Обещание',
        level4: getFullUrl('/health/o-proekte/'),
      });
    },
  },
});
