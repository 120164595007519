// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/arrow.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arrow-button[data-v-31d098ab]{background:none;border:none;width:32px;height:100%;padding:16px 8px;cursor:pointer;display:none;align-items:center;justify-content:flex-end}@media(min-width:1024px){.arrow-button[data-v-31d098ab]{display:flex}}.arrow-button[data-v-31d098ab]:before{position:absolute;pointer-events:none;content:\"\";width:16px;height:16px;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");top:50%;transform:translateY(-50%)}.arrow-button--red[data-v-31d098ab]:before{background-color:#fff}.arrow-button--light[data-v-31d098ab]:before{background-color:#969698}@media(hover:hover){.arrow-button--light[data-v-31d098ab]:hover:before{background-color:#000}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
